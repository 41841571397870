// 评价审核
<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
export default {
  name: 'busStation',
  data() {
    return {
      ...api.command.getState(),
      tableData: []
    }
  },
  mounted() {
    api.command.getList
      .call(this, {
        url: '/config/farmBusStation/page',
        current: 1
      })
      .then(result => {
        this.tableData = result.data.records
      })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '地址',
          type: 'input',
          key: 'address'
        },
        {
          label: '',
          type: 'text',
          key: 'a1'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'lon',
          title: '位置',
          align: 'left',
          sorter: (a, b) => a.lon - b.lon,
          customRender: (text, records) => {
            return `经度：${records.lon} 纬度：${records.lat}`
          },
          onExport: (text, records) => {
            return `经度：${records.lon} 纬度：${records.lat}`
          }
        },
        {
          dataIndex: 'address',
          title: '地址',
          align: 'left',
          customRender: function(text, records) {
            return records.address == null ? '' : <div>{records.regions + ' ' + records.address}</div>
          },
          onExport: (text, records) => {
            return records.regions + ' ' + records.address
          }
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () =>
                  this.$router.push({
                    path: '/publicInfo/busStationDetail',
                    query: {
                      id: records.id
                    }
                  })
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () => {
                  api.command.delPost.call(this, {
                    url: `/config/farmBusStation/delete?id=${records.id}`
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/publicInfo/busStationDetail')
        },
        // {
        //   name: '地图浏览',
        //   type: 'primary',
        //   onClick: this.openMap
        // },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/config/farmBusStation/deleteBatch',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    },
    // openMap() {
    //   const markerList = this.tableData.map(e => ({
    //     lng: e.lon,
    //     lat: e.lat
    //   }))

    //   apiTool.openMapMarkers({
    //     title: '地图标记点',
    //     value: markerList
    //   })
    // },
    handleMapPreview() {
      return [
        {
          name: '地图预览',
          type: 'primary',
          onClick: () => {
            const markerList = this.records.map(e => ({
              lng: e.lon,
              lat: e.lat
            }))

            apiTool.openMapMarkers({
              title: '地图标记点',
              value: markerList
            })
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        rightButton={this.handleMapPreview()}
        records={this.records}
      />
    )
  }
}
</script>
